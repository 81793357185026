import React, { useState } from 'react';
import { graphql } from "gatsby"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"

export const pageQuery = graphql`
 query {
    allStrapiPrivacy {
        edges {
            node {
                seo {
                    metaTitle
                    metaDescription
                    shareImage {
                    url
                    }
                },
                title,
                content
            }
        }
    }
 }
`


export default function Privacy({ location,data }) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    const node = data.allStrapiPrivacy.edges[0].node
    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="container mx-auto pt-[200px] pb-[100px] flex items-start justify-between xl:flex-col xl:pt-24 xl:pb-20">
                <h1 className="text-[40px] leading-snug xl:mb-8">{node.title}</h1>
                <article className="w-[944px] about xl:w-full" dangerouslySetInnerHTML={{__html: node.content}} />
            </div>
        </Layout>
    )
}

